import React, { useState } from "react";
import PaymentPage from "./Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
        Choose Your Plan
      </h2>

      <div className="space-y-4">
        <div
          className="p-4 bg-gray-100 rounded-lg shadow-sm cursor-pointer hover:bg-gray-200"
          onClick={() =>
            handleSelectPlan({
              title: "Standard Plan",
              name: "standard_plan-USD-Monthly",
              amount: 25,
            })
          }
        >
          <h3 className="text-xl font-semibold text-gray-800">Standard Plan</h3>
          <p className="text-sm text-gray-600">Great for small teams</p>
          <span className="text-xl font-semibold text-gray-800">$25/month</span>
        </div>
      </div>

      {isModalOpen && selectedPlan && (
        <Elements stripe={stripePromise}>
          <PaymentPage
            plan={selectedPlan}
            onClose={() => setIsModalOpen(false)}
          />
        </Elements>
      )}
    </div>
  );
};

export default SubscriptionPage;
