import React, { useEffect, useState } from "react";

const PlayersList = (props) => {
  const [teamStats, setTeamStats] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.sportsdata.io/api/nba/odds/json/TeamSeasonStats/2024?key=b9fe5f77313f485d823f08c6b4df1118"
    )
      .then((response) => response.json())
      .then((data) => setTeamStats(data))
      .catch((error) => console.error("Error fetching team stats:", error));
  }, []);

  const calculatePace = (team) => {
    const {
      FieldGoalsAttempted,
      FreeThrowsAttempted,
      OffensiveRebounds,
      Turnovers,
      Games,
    } = team;
    return (
      (FieldGoalsAttempted +
        0.44 * FreeThrowsAttempted -
        OffensiveRebounds +
        Turnovers) /
      2 /
      Games
    );
  };

  const calculatePaceAdjustedProjection = (player) => {
    const playerTeamStats = teamStats.find((team) => team.Team === player.Team);
    const opponentTeamStats = teamStats.find(
      (team) => team.Team === player.Opponent
    );

    if (!playerTeamStats || !opponentTeamStats)
      return player.ProjectedFantasyPoints;

    const playerTeamPace = +calculatePace(playerTeamStats);
    const opponentTeamPace = +calculatePace(opponentTeamStats);

    const avgPace = (playerTeamPace + opponentTeamPace) / 2;
    const paceDifferential = +avgPace - playerTeamPace;
    const extraPossessionsPerMinute = +paceDifferential / 48;
    const extraPossessions =
      +extraPossessionsPerMinute * +player.ProjectedMinutes;

    const paceAdjustedFantasyPoints =
      +player.ProjectedFantasyPoints +
      +extraPossessions * (+player.AvgFPPM / 2.125);

    return paceAdjustedFantasyPoints;
  };

  return (
    <tbody>
      {props.players !== null && props.players.length > 0 ? (
        props.players.map((player, index) => (
          <tr
            key={index}
            className="border"
            style={{ border: "1px solid black" }}
          >
            <td style={{ border: "1px solid black" }} className="py-1">
              {index + 1}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.Name}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.Position}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.Team}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.Opponent !== "NaN"
                ? player.Opponent
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.DKSalary !== "NaN"
                ? player.DKSalary
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.HomeOrAway !== "NaN"
                ? player.HomeOrAway
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.AvgFPPM !== "NaN"
                ? player.AvgFPPM
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.AvgFPPMHome !== "NaN"
                ? player.AvgFPPMHome
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.AvgFPPMAway !== "NaN"
                ? player.AvgFPPMAway
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.AvgFPPMLast5 !== "NaN"
                ? player.AvgFPPMLast5
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.AvgFPPMOpponent !== "NaN"
                ? player.AvgFPPMOpponent
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.SDProjectedFPPM !== "NaN"
                ? player.SDProjectedFPPM
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.ProjectedMinutes !== "NaN"
                ? player.ProjectedMinutes
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.ProjectedFantasyPoints !== "NaN"
                ? player.ProjectedFantasyPoints
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {player.FantasyValue !== "NaN"
                ? player.FantasyValue
                : parseFloat(0.0).toFixed(3)}
            </td>
            <td style={{ border: "1px solid black" }} className="py-1">
              {calculatePaceAdjustedProjection(player)}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td
            colSpan="17"
            className="py-1"
            style={{ border: "1px solid black" }}
          >
            No players found
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default PlayersList;
